aside {
  position: sticky;
  top: 0;
  z-index: 1;
}

.aside__buttons {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$header-lg-height});
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;

  @include media-breakpoint-down(xxl) {
    padding-top: 23rem;
  }

  .btn--content-slide {
    @extend .my-2;
    pointer-events: auto;

    .btn--content-slide__btn {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.aside__header {
  position: sticky;
  top: 0;
  z-index: 99;

  @include media-breakpoint-down(lg) {
    border-bottom: 1px $white solid;
  }

  .btn {
    font-size: $font-size-sm;
    line-height: 1.25rem;

    &:first-child {
      border-right: 1px $white solid;
    }

    &:last-child {
      border-left: 1px $white solid;
    }

    img {
      max-height: 1rem;
    }
  }
}
