.card--gray {
  background-color: $gray-100;
  border: 0;

  .card--gray__label {
    margin-bottom: 1rem;

    &:nth-child(4) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      &:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }

  .card--gray__label__img {
    margin-right: 0.5rem;
    min-width: 1.5rem;
  }
}

.card--content {
  border-width: 0;

  @include media-breakpoint-down(lg) {
    border-radius: 0;

    &.card--step > .card-body {
      padding-bottom: 7rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 2rem;

      &.card-sm-pt-0 {
        padding-top: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    border-radius: 3.125rem;
    box-shadow: 10px 10px 0 rgba($primary, 0.1);
    margin-bottom: 4rem;

    > .card-body {
      padding: 2.5rem;
    }
  }

  &.card--content--rounded-4-5 {
    @include media-breakpoint-up(lg) {
      border-radius: 1.5rem;
    }
  }
}

.card--calculation {
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 2rem;
  }

  @include media-breakpoint-down(lg) {
    --bs-card-bg: #{$gray-100};
    --bs-card-border-radius: 0;
    bottom: 0;
    box-shadow: 0 0 12px rgba($black, 0.25);
    position: fixed;
    width: 100vw;
    z-index: 9;
  }

  > .card-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@include media-breakpoint-down(lg) {
  .card--calculation__hr {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}
