// Font
@import "./scss/font";

// Variables
@import "./scss/variables";

// Third party
@import "./scss/bootstrap";

// General
@import "./scss/app";

// Elements
@import "./scss/elements/amount";
@import "./scss/elements/badge";
@import "./scss/elements/card";
@import "./scss/elements/form";
@import "./scss/elements/loader";

// Components
@import "./components/aside/aside";
@import "./components/calculation/calculation";
@import "./components/footer/footer";
@import "./components/header/header";
@import "./components/material/button/button";
@import "./components/hero/hero";
@import "./components/modal/modal";
@import "./components/progress/progress";
@import "./components/unitPrice/unitPrice";
@import "./components/zipCode/zipCode";

// Pages
@import "./pages/account/account";
@import "./pages/discount/discount";
@import "./pages/summary/summary";

// React notifications
@import "~react-notifications/dist/react-notifications.css";
@import "~react-notifications/lib/notifications.css";
