.h1 {
  .spinner-border-container {
    position: absolute;
    top: #{$h1-font-size/2};
    transform: translateY(-50%);
  }

  .spinner-border-container--align-center {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .spinner-border {
    --bs-spinner-border-width: 0.15em;
    --bs-spinner-height: 0.9rem;
    --bs-spinner-width: 0.9rem;
  }
}
