.amount--min-width {
  max-width: 100%;
  min-height: 2.625rem;
  min-width: 140px;
}

.amount--min-width-small {
  max-width: 100%;
  min-width: 125px;
}

.amount {
  .h1 {
    margin-bottom: 0;

    small {
      position: relative;
      text-transform: lowercase;
      top: -0.25rem;
    }
  }
}
