@font-face {
  font-family: "Crossten";
  font-weight: 400;
  src: url("/assets/fonts/HorizonType-CrosstenBook.otf") format("truetype");
}

@font-face {
  font-family: "Crossten";
  font-weight: 500;
  src: url("/assets/fonts/HorizonType-CrosstenMedium.otf") format("truetype");
}

@font-face {
  font-family: "Crossten";
  font-weight: 700;
  src: url("/assets/fonts/HorizonType-CrosstenBold.otf") format("truetype");
}

@font-face {
  font-family: "Crossten";
  font-weight: 900;
  src: url("/assets/fonts/HorizonType-CrosstenBlack.otf") format("truetype");
}
