// Color system
$primary: #1946ff;
$primary-200: #f2f8ff;
$primary-600: #0c34d4;
$secondary: #f72aee;
$light: $primary-200;

$white: #ffffff;
$gray-100: #f7f8ff;
$gray-200: #e2e2e2;
$gray-400: #cecece;
$gray-600: #848484;
$black: #000000;

$yellow: #fab942;

$danger: #ff0000;

// Options
$body-color: #000000;

$hr-margin-y: 2rem;
$hr-color: $gray-400;
$hr-opacity: 1;

// Body
$body-bg: $primary-200;

// Options
$enable-validation-icons: false;

// Typography
$font-family-sans-serif: "Crossten", Verdana, sans-serif;
$font-size-base: 0.875rem;
$font-size-sm: 0.75rem;

$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;

$h1-font-size: $font-size-base * 2.42857 !default;
$h2-font-size: $font-size-base * 1.7142857 !default;
$h3-font-size: $font-size-base * 1.142857 !default;
$h4-font-size: $font-size-base !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;

$headings-font-weight: $font-weight-bold;
$headings-color: $primary;

$line-height-base: 1.28571428571;

$small-font-size: 0.85714em;

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1096px,
) !default;

// Components
$border-radius: 1.25rem;

// Buttons + Forms
$btn-disabled-opacity: 0.5;
$btn-font-weight: $font-weight-medium;

// Form
$input-placeholder-color: var(--bs-gray-600);
$focus-ring-opacity: 0;
$form-text-color: var(--bs-primary) !default;
$input-bg: $gray-100;
$input-color: $gray-600;
$input-border-width: 1px;
$input-border-color: $gray-100;
$input-focus-border-color: $primary;
$form-feedback-valid-color: inherit;
$form-check-input-width: 1.14285714286em;
$form-check-input-border: 1px solid $primary;
$form-check-input-checked-border-color: $primary;
$form-check-input-border-radius: 0;
$form-check-input-checked-bg-color: $input-bg;

// Form validation
$form-check-input-checked-bg-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEwIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0zLjMzMzMzIDguNUwwIDUuMTY2NjdMMS4zMzMzMyAzLjgzMzMzTDMuMzMzMzMgNS44MzMzM0w4LjY2NjY3IDAuNUwxMCAxLjgzMzMzTDMuMzMzMzMgOC41WiIgZmlsbD0iIzE5NDZGRiIvPgo8L3N2Zz4K");
$form-check-radio-checked-bg-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjUgMC41QzQuMDgxNzIgMC41IDAuNSA0LjA4MTcyIDAuNSA4LjVDMC41IDEyLjkxODMgNC4wODE3MiAxNi41IDguNSAxNi41QzEyLjkxODMgMTYuNSAxNi41IDEyLjkxODMgMTYuNSA4LjVDMTYuNSA0LjA4MTcyIDEyLjkxODMgMC41IDguNSAwLjVaIiBmaWxsPSIjMTk0NkZGIi8+CjxwYXRoIGQ9Ik04LjUgMTAuODMzM0M3LjU3OTUyIDExLjc1MzggNi4wODcxNCAxMS43NTM4IDUuMTY2NjcgMTAuODMzM0w0LjE2NjY3IDkuODMzMzNDMy43OTg0OCA5LjQ2NTE0IDMuNzk4NDggOC44NjgxOSA0LjE2NjY3IDguNUM0LjUzNDg2IDguMTMxODEgNS4xMzE4MSA4LjEzMTgxIDUuNSA4LjVMNS44MzMzMyA4LjgzMzMzQzYuMzg1NjIgOS4zODU2MiA3LjI4MTA1IDkuMzg1NjIgNy44MzMzMyA4LjgzMzMzTDExLjUgNS4xNjY2N0MxMS44NjgyIDQuNzk4NDggMTIuNDY1MSA0Ljc5ODQ4IDEyLjgzMzMgNS4xNjY2N0MxMy4yMDE1IDUuNTM0ODYgMTMuMjAxNSA2LjEzMTgxIDEyLjgzMzMgNi41TDguNSAxMC44MzMzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");

$form-valid-border-color: $input-bg;

// Cards
$card-spacer-y: 1rem;
$card-spacer-x: 1rem;
$card-border-radius: 0.5rem;
$card-border-width: 1px;
$card-border-color: $gray-200;
$card-bg: $white;

// Button
$input-btn-line-height: 1.85714285714;
$input-btn-padding-x: 1rem;

// Custom
// Gradients
$gradient-secondary: linear-gradient(224deg, $secondary 0%, $yellow 100%);
$gradient-secondary-active: linear-gradient(216deg, $secondary 0%, $yellow 100%);

// Badges
$badge-font-weight: 100;

// Modal
$modal-inner-padding: 1.5rem;
$modal-sm: 414px;
$modal-content-bg: $white;
$modal-content-border-width: 0;
$modal-footer-margin-between: 0rem;

// Header
$header-height: 72px;
$header-lg-height: 80px;

// Form
$form-check-radio-bg-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjI1IDAuNUM0LjgzMTcyIDAuNSAxLjI1IDQuMDgxNzIgMS4yNSA4LjVDMS4yNSAxMi45MTgzIDQuODMxNzIgMTYuNSA5LjI1IDE2LjVDMTMuNjY4MyAxNi41IDE3LjI1IDEyLjkxODMgMTcuMjUgOC41QzE3LjI1IDQuMDgxNzIgMTMuNjY4MyAwLjUgOS4yNSAwLjVaIiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjQ0VDRUNFIi8+Cjwvc3ZnPgo=");

// Typography
$letter-spacing: 0.275px;
