header {
  background-color: $white;

  .header__top {
    height: $header-height;

    @include media-breakpoint-up(lg) {
      height: $header-lg-height;
      padding-left: 52px;
      padding-right: 52px;
    }

    @include media-breakpoint-between(lg, xxl) {
      margin-bottom: 1.5rem;
    }

    @include media-breakpoint-up(xxl) {
      margin-bottom: 3rem;
    }
  }

  .logo {
    height: 24px;

    @include media-breakpoint-up(lg) {
      height: 37px;
    }
  }
}

.language__item {
  font-size: $font-size-sm;
  opacity: 0.2;

  @include media-breakpoint-up(lg) {
    margin-left: 0.5rem;
  }

  &.language__item--selected {
    opacity: 1;
  }

  .language__item__flag {
    margin-right: 0.5rem;
    width: 1rem;
  }
}
