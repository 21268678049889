.btn-gray-400 {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-border-color: var(--bs-gray-400);
  --bs-btn-hover-border-color: #{$gray-600};
  --bs-btn-padding-y: 1rem;
}

.btn {
  letter-spacing: $letter-spacing;

  img {
    width: 1rem;
  }

  .spinner-border {
    --bs-spinner-height: 0.75rem;
    --bs-spinner-width: 0.75rem;
    --bs-spinner-border-width: 0.125rem;
    margin-top: 0.25rem;
  }

  span + .spinner-border {
    @extend .ms-2;
  }
}

.btn-primary {
  --bs-btn-hover-bg: #{$primary-600};
}

.btn-secondary {
  --bs-btn-color: #{$white};
  --bs-btn-hover-color: #{$white};
  --bs-btn-active-color: #{$white};
  --bs-btn-disabled-color: #{$white};

  background: $gradient-secondary;
  border-width: 0;
  overflow: hidden;
  position: relative;
  z-index: 1; /* matters! */

  &::before {
    background: $gradient-secondary-active;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.25s ease-in-out;
    width: 100%;
    z-index: -1;
  }

  &:hover::before {
    opacity: 1;
  }

  &[type="submit"] {
    white-space: nowrap;
  }
}

.btn-sm {
  font-weight: 700;
}

.btn-outline-primary--circle {
  border-radius: 1rem;
  border-width: 2px;
  display: flex;
  font-size: 2rem;
  height: 2rem;
  justify-content: center;
  line-height: 1.5rem;
  padding: 0;
  width: 2rem;

  @include media-breakpoint-up(lg) {
    border-radius: 0.75rem;
    font-size: 1.5rem;
    height: 1.5rem;
    line-height: 1.1rem;
    width: 1.5rem;
  }

  &.disabled {
    opacity: 0.25;
  }
}

.btn-light {
  border-width: 0;
}

.btn-link {
  --bs-btn-hover-color: #{$primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// btn content
.btn--content {
  > .btn--content__content {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > span {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: 0.25rem;
      }

      p {
        line-height: 1;
        margin-bottom: 0;
      }
    }
  }
}
// -- btn content

// btn content slide
.btn--content-slide {
  $btn-content-content-width: 100px;
  $btn-content-content-width-hover: 280px;
  $btn-content-height: 90px;
  $btn-transition: all 0.25s ease-in-out;
  cursor: pointer;
  height: $btn-content-height;
  position: relative;
  transition: $btn-transition;
  width: $btn-content-content-width;

  .btn--content-slide__slide {
    align-items: center;
    box-shadow: 0px 0px 8px 0px #0000001a;
    display: flex;
    height: $btn-content-height;
    overflow: hidden;
    position: absolute;
    transition: $btn-transition;
    width: $btn-content-content-width;
    --bs-btn-padding-y: 1rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-color: #{$black};
    --bs-btn-hover-color: #{$black};
    --bs-btn-bg: #{$white};
    --bs-btn-hover-bg: #{$white};
    --bs-btn-border-radius: 0.5rem;
    @extend .btn, .btn-link;

    .btn--content-slide__slide__content {
      display: flex;
      flex-direction: column;
      justify-content: left;
      text-align: left;
      white-space: nowrap;

      p {
        font-size: $small-font-size;
        margin-bottom: 0;
      }
    }
  }

  .btn--content-slide__btn {
    border-radius: 0.5rem;
    height: $btn-content-height;
    overflow: hidden;
    position: absolute;
    right: 0;
    transition: $btn-transition;
    width: $btn-content-content-width;

    &::before {
      background-color: $white;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      width: calc($btn-content-content-width - 10px);
      z-index: 2;
    }

    &::after {
      box-shadow: 0px 0 10px $white;
      content: "";
      height: 100%;
      left: 10px;
      position: absolute;
      top: 0;
      width: 10px;
      z-index: 1;
    }

    .btn--content-slide__btn__content {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      position: relative;
      z-index: 3;

      img {
        @extend .icon, .icon__lg;
        margin-bottom: 0.5rem;
      }
    }
  }

  &:hover {
    width: $btn-content-content-width-hover;

    .btn--content-slide__slide {
      text-decoration: none;
      width: $btn-content-content-width-hover;
    }
  }
}
// -- btn content slide

.btn__icon__sm img {
  width: 1rem;
}
