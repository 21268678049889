.hero {
  height: 100%;
  min-height: 320px;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down(sm) {
    margin-left: -1.5rem;
    width: 100vw;
  }

  @include media-breakpoint-up(sm) {
    border-radius: 1.5rem;
  }

  &::before {
    background-position: center left -200px;
    background-repeat: no-repeat;
    content: "";
    inset: 0;
    position: absolute;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      background-position: center left;
    }
  }

  &.hero--primary::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTE0IiBoZWlnaHQ9IjY3MCIgdmlld0JveD0iMCAwIDUxNCA2NzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuNzUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNLTcuODg0MzIgLTAuNUMtOTAuMjM1OCAtMC41IC0xNTcgNTkuNTg1OSAtMTU3IDEzMy43MDVDLTE1NyAxODEuMTkzIC0xMjkuNTE3IDIyMi44MyAtODguMTU0MSAyNDYuNjc4TC04OC4yNTE1IDI0Ni44M0w3NC45NTk0IDMzNUwtODguMjUxNSA0MjMuMTc1TC04OC4xNTQxIDQyMy4zMjJDLTEyOS41MTcgNDQ3LjE3NSAtMTU3IDQ4OC44MTIgLTE1NyA1MzYuM0MtMTU3IDYxMC40MTkgLTkwLjIzNTggNjcwLjUgLTcuODg0MzIgNjcwLjVDMTguODUwMSA2NzAuNSA0My44OTI0IDY2NC4wODYgNjUuNTkxNiA2NTIuOTkyTDY1LjYzMjYgNjUzLjA1N0w0NDIuNDcyIDQ0OS43MzZMNDQyLjMxOSA0NDkuNTQ3QzQ4NS4yMzUgNDI1Ljk4NSA1MTQgMzgzLjU2OCA1MTQgMzM1QzUxNCAyODYuNDI3IDQ4NS4yMzUgMjQ0LjAxNSA0NDIuMzE5IDIyMC40NThMNDQyLjQ3MiAyMjAuMjZMNjUuNjMyNiAxNi45NTIyTDY1LjU5MTYgMTcuMDEyMkM0My44OTI0IDUuOTE4ODIgMTguODUwMSAtMC41IC03Ljg4NDMyIC0wLjVaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfNjkzN18xMDY2MSkiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl82OTM3XzEwNjYxIiB4MT0iLTE1NyIgeTE9IjY3MC41IiB4Mj0iNTEyLjM1NyIgeTI9IjI2LjMzMjQiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzM1RTlGQyIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMxOTQ2RkYiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K");
  }

  &.hero--secondary::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTE1IiBoZWlnaHQ9IjY3MSIgdmlld0JveD0iMCAwIDUxNSA2NzEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuNzUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNLTcuNjM0MzIgMC4yNUMtODkuOTg1OCAwLjI1IC0xNTYuNzUgNjAuMzM1OSAtMTU2Ljc1IDEzNC40NTVDLTE1Ni43NSAxODEuOTQzIC0xMjkuMjY3IDIyMy41OCAtODcuOTA0MSAyNDcuNDI4TC04OC4wMDE1IDI0Ny41OEw3NS4yMDk0IDMzNS43NUwtODguMDAxNSA0MjMuOTI1TC04Ny45MDQxIDQyNC4wNzJDLTEyOS4yNjcgNDQ3LjkyNSAtMTU2Ljc1IDQ4OS41NjIgLTE1Ni43NSA1MzcuMDVDLTE1Ni43NSA2MTEuMTY5IC04OS45ODU4IDY3MS4yNSAtNy42MzQzMiA2NzEuMjVDMTkuMTAwMSA2NzEuMjUgNDQuMTQyNCA2NjQuODM2IDY1Ljg0MTYgNjUzLjc0Mkw2NS44ODI2IDY1My44MDdMNDQyLjcyMiA0NTAuNDg2TDQ0Mi41NjkgNDUwLjI5N0M0ODUuNDg1IDQyNi43MzUgNTE0LjI1IDM4NC4zMTggNTE0LjI1IDMzNS43NUM1MTQuMjUgMjg3LjE3NyA0ODUuNDg1IDI0NC43NjUgNDQyLjU2OSAyMjEuMjA4TDQ0Mi43MjIgMjIxLjAxTDY1Ljg4MjYgMTcuNzAyMkw2NS44NDE2IDE3Ljc2MjJDNDQuMTQyNCA2LjY2ODgyIDE5LjEwMDEgMC4yNSAtNy42MzQzMiAwLjI1WiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzY5MzdfMTA2NjgpIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfNjkzN18xMDY2OCIgeDE9IjM3Ni4wNTIiIHkxPSI1NzcuNzAxIiB4Mj0iNjU5Ljc4NyIgeTI9IjcxLjAxNzEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0Y3MkFFRSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGMUIzNDkiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K");
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 280px;
    padding: 1rem 1rem 1rem 1.5rem;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(sm) {
      max-width: 450px;
      padding: 1rem 1rem 1rem 7rem;
    }

    .label {
      color: $white;
      margin-bottom: 0.5rem;
    }

    h1 {
      color: $white;
      margin-bottom: 0;
    }
  }

  img {
    height: 100%;
    inset: 0;
    object-fit: cover;
    object-position: right;
    position: absolute;
    width: 100%;

    @include media-breakpoint-up(sm) {
      object-position: center;
    }
  }
}
