body {
  --bs-btn-active-border-color: transparent;
}

// Colors
.text-gray-400 {
  color: $gray-400;
}

.text-gray-600 {
  color: $gray-600;
}

// Paragraph
p {
  letter-spacing: $letter-spacing;
}

// Headers
h1,
.h1 {
  font-weight: 900;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    margin-bottom: 2.5rem;
  }

  small {
    font-size: $h2-font-size;
    text-transform: unset;
  }
}

h2,
.h2 {
  margin-bottom: 2rem;
}

h3,
.h3 {
  img:nth-child(1) {
    margin-right: 0.25rem;
  }

  &.d-flex {
    img:nth-child(1) {
      margin-right: 0.5rem;
    }
  }
}

// Image
img {
  max-width: 100%;

  &.img-full {
    @include media-breakpoint-down(lg) {
      margin-left: -#{$spacer};
      margin-right: -#{$spacer};
      max-width: 100vw;
    }
  }
}

@include media-breakpoint-up(lg) {
  .rounded-lg-4-5 {
    border-radius: 1.5rem;
  }
}

// Link
a {
  --bs-link-color-rgb: $body-color;

  &:hover {
    --bs-link-hover-color-rgb: $body-color;
    text-decoration: none;
  }
}

// Icon
.icon {
  width: 100% !important;

  &.icon__lg {
    max-width: 2rem;
  }

  &.icon__md {
    max-width: 1.5rem;
  }

  &.icon__sm {
    max-width: 1rem;
  }
}

// Divider
hr {
  @extend .mb-lg-md;
}

// Margins
.mb-md {
  margin-bottom: 2rem;
}

.mb-lg-lg {
  margin-bottom: 2.5rem;
}

.mb-lg-md {
  margin-bottom: 2.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
  }
}

.mb-md-sm {
  @include media-breakpoint-down(lg) {
    margin-bottom: 2rem;
  }
}

// Arrow
.arrow {
  border: solid $primary;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0.375rem;
  vertical-align: middle;
}

.arrow--down {
  transform: rotate(45deg);

  .btn & {
    margin-top: -0.5rem;
  }
}

.arrow--up {
  transform: rotate(-135deg);

  .btn & {
    margin-top: 0.5rem;
  }
}

// Container
@include media-breakpoint-down(lg) {
  .container__content > .row > * {
    padding-left: 0;
    padding-right: 0;
  }
}

// Updated animation
.h1.updated_animation {
  min-height: $h1-font-size;
  position: relative;
}

.updated_animation__container {
  position: absolute;

  &.updated_animation__container--is-updated {
    animation: 450ms ease-in-out animation-updated;
  }
}

@keyframes animation-updated {
  0% {
    color: $primary;
    transform: scale(1);
  }

  20%,
  80% {
    color: $yellow;
    transform: scale(1.05);
  }

  100% {
    color: $primary;
    transform: scale(1);
  }
}
