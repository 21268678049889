.progress {
  @extend .small;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 2rem;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin: 0 2rem 3.5rem;
  }

  &::before {
    background-color: $gray-200;
    content: "";
    height: 2px;
    position: absolute;
    width: 100%;
  }
}

.progress__item {
  background: $gradient-secondary;
  border-radius: 12px;
  box-shadow: 0 0 0 0.25rem $white;
  height: 24px;
  position: relative;
  width: 24px;

  .progress__item--step {
    display: inline-block;
    height: 100%;
    width: 100%;

    &::after {
      color: $white;
      content: attr(data-step);
      font-weight: $font-weight-bold;
      height: 100%;
      line-height: 24px;
      position: absolute;
      text-align: center;
      width: calc(100% - 2px);
    }
  }

  .progress__item--label {
    color: $gray-600;
    left: 50%;
    position: absolute;
    top: 2.25em;
    transform: translateX(-50%);
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &.progress__item--checked,
  &.progress__item--active {
    .progress__item--label {
      font-weight: $font-weight-bold;
    }
  }

  &.progress__item--checked .progress__item--step {
    background-image: url("/assets/icon-check-white.svg");
    background-position: center center;
    background-repeat: no-repeat;
    &::after {
      content: unset;
    }
  }

  &.progress__item--active {
    .progress__item--step::after {
      width: 100%;
    }

    &:not(.progress__item--checked) {
      background: $primary;
    }
  }

  &:not(.progress__item--active):not(.progress__item--checked) {
    .progress__item--step {
      background-color: $white;
      border-radius: 10px;
      height: calc(100% - 2px);
      margin-left: 1px;
      margin-top: 1px;
      width: calc(100% - 2px);

      &::after {
        color: $secondary;
      }
    }
  }
}
