.summary {
  .btn img {
    max-width: unset;
  }

  .btn--summary__edit-h2 {
    margin-top: -0.25rem;
  }

  .btn--summary__edit-h3 {
    margin-top: -0.625rem;
  }

  @include media-breakpoint-down(lg) {
    .summary__calculation-settings {
      margin-bottom: -1rem;
    }
  }
}
