.modal {
  --bs-modal-header-padding: 1.5rem 1.5rem 0;

  @include media-breakpoint-up(md) {
    --bs-modal-header-padding: 2.5rem 2.5rem 0;
  }
}

.modal-header {
  .btn-close {
    --bs-btn-close-opacity: 1;
    --bs-btn-close-bg: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuNzkyODYgMS4xOTI4M0wyLjgzMzk1IDEuMjMxOTNMMTAuNTAwNCA4Ljg5MzUxTDE4LjE2NjggMS4yMzE5M0MxOC42MTA4IDAuNzg4MjcyIDE5LjMyODcgMC43OTAxMTcgMTkuNzcwMyAxLjIzNjA1QzIwLjE5ODYgMS42Njg0OCAyMC4yMDk5IDIuMzU5NyAxOS44MDU0IDIuODA1NzNMMTkuNzY2MiAyLjg0NjgxTDEyLjEwOCAxMC41MDA0TDE5Ljc2NjIgMTguMTU0QzIwLjIxMDIgMTguNTk3NiAyMC4yMTIgMTkuMzE4OCAxOS43NzAzIDE5Ljc2NDdDMTkuMzQyMSAyMC4xOTcyIDE4LjY1NCAyMC4yMTIgMTguMjA3OSAxOS44MDhMMTguMTY2OCAxOS43Njg4TDEwLjUwMDQgMTIuMTA3TDIuODMzOTUgMTkuNzY4OEMyLjM5MDAyIDIwLjIxMjUgMS42NzIxMSAyMC4yMTA3IDEuMjMwNDQgMTkuNzY0N0MwLjgwMjE2MSAxOS4zMzIzIDAuNzkwOTA5IDE4LjY0MTEgMS4xOTU0IDE4LjE5NTFMMS4yMzQ1NCAxOC4xNTRMOC44OTI1OCAxMC41MDA0TDEuMjM0NTQgMi44NDY4MUMwLjc5MDYxMyAyLjQwMzE1IDAuNzg4Nzc3IDEuNjgxOTkgMS4yMzA0NCAxLjIzNjA1QzEuNjQ1NzUgMC44MTY3MzQgMi4zMDUzMSAwLjc5MDA3NSAyLjc1MTY1IDEuMTU3MjVMMi43OTI4NiAxLjE5MjgzWiIgZmlsbD0iIzE5NDZGRiIvPgo8L3N2Zz4K");

    background: transparent var(--bs-btn-close-bg) center/1em no-repeat;
    border-radius: 0;
    font-size: 1.2rem;
    height: 1.5rem;
    margin: 0;
    padding: 0;
    width: 1.5rem;
  }
}

.modal-body {
  padding: 2rem 1.5rem;

  @include media-breakpoint-up(md) {
    padding: 2rem 2.5rem;
  }
}

.modal-footer {
  padding: 0 1.5rem 1.5rem;

  @include media-breakpoint-up(md) {
    padding: 0 2.5rem 2.5rem;
  }
  .btn {
    margin: 0;
  }
}

.modal__content {
  @extend .mb-md, .text-center;

  h2 {
    font-weight: 900;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}
