.form-check.form-check-inline {
  flex: 1 1 auto;
  line-height: 3.125rem;
  padding-left: 0;

  .form-check-label {
    border: $border-width $gray-200 solid;
    border-radius: $border-radius-sm;
    cursor: pointer;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    margin-left: -2.25rem;
    max-width: 100%;
    min-width: 140px;
    padding-left: 3rem;
    width: 100%;
  }

  .form-check-input {
    &:not(:checked[type="radio"]) {
      --bs-form-check-bg-image: #{$form-check-radio-bg-image};
    }
    border: 0;
    height: 1rem;
    margin-left: 1.25rem;
    position: relative; //necessary
    width: 1rem;

    &:checked[type="radio"],
    &:hover {
      border-width: 0;

      + .form-check-label {
        border-color: $primary;
      }
    }
  }
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label,
.form-control.is-invalid,
.was-validated .form-control:invalid {
  // !important added to prevent default behavior of bootstrap :invalid on a element precedes custom css
  border-color: $danger !important;
  color: inherit;
}

@include media-breakpoint-up(sm) {
  .form-control--placeholder-sm-none::placeholder {
    color: $input-bg;
  }
}

.form-check-input:checked[type="checkbox"] {
  background-size: 10px auto;
}

.form-check-split {
  margin: 0 0 1rem 0;
}

.form-check-input-large {
  &:checked[type="radio"] {
    top: -2.375rem;
  }
}

.form-check-input-large-title {
  padding-left: 2rem;
}
